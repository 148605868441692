import styled from "styled-components"
import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import Button, { BaseButton, IconContainer, ButtonLabel } from "./Button"
import InformationSVG from "../../Svg/Information"
import { mediaMin, mediaMax } from "../../../utils/breakpoints"

interface InfoButtonProps {
  className?: string
  onClick?: () => void
  text?: string
  to?: string
  myDisabled?: boolean
}

export default ({
  className,
  onClick,
  to,
  text = "Upplýsingar",
  myDisabled,
}: InfoButtonProps) => {
  const disabledClass = myDisabled ? "disabled" : ""
  if (to) {
    return (
      <InfoLink href={to} as={"a"} className={`${className} ${disabledClass}`}>
        <IconContainer>
          <InformationSVG
            fill="none"
            stroke="#303030"
            width="21px"
            className="icon"
          />
        </IconContainer>

        <ButtonLabel text={text} />
      </InfoLink>
    )
  }

  return (
    <InfoButton
      type="button"
      className={className}
      onClick={onClick}
      icon={
        <InformationSVG
          fill="none"
          stroke="#303030"
          width="21px"
          className="icon"
        />
      }
      text={text}
      myDisabled={myDisabled}
    />
  )
}

const InfoLink = styled(BaseButton)`
  text-decoration: none;

  &:visited,
  &:focus {
    outline: none;
    color: #303030;
  }

  @media ${mediaMax.tablet} {
    display: none;
  }
`

const InfoButton = styled(Button)``
