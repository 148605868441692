
import { Inputs, Input } from '../../types/inputs'
import { encode } from './utils'

const FORM_NAME = "EventRegister"

export default (inputs: any) => {
	// TODO: Staging
	if(process.env.NODE_ENV !== "production") { 
		return new Promise((resolve) => resolve())
	}

	return new Promise((resolve, reject) => {
		fetch("/", {
			method: "POST",
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
			body: encode({ 
				"form-name": FORM_NAME, 
				["name"]: inputs.name.value,
				["company"]: inputs.company.value,
				["email"]: inputs.email.value
			})
		})
		.then(resolve)
		.catch(reject)
			// .then( () => {
			// 	if (typeof window === undefined) {return}
			// 	window.dataLayer = window.dataLayer || [];
			// 	window.dataLayer.push({'event': 'skraning_haustradstefna'});
			// 	resolve();	
			// })
	})
}