import React, { useState, useEffect, useRef } from 'react'
import Cookies from 'js-cookie'
import styled from 'styled-components';
import { mediaMax } from '../../utils/breakpoints'
import CheckMarkConsent from "../Svg/CheckmarkConsent";
import CloseSVG from '../Svg/Close';
import { theme } from '../../styles';

export default () => {
	const [cookieConsent, setCookieConsent] = useState(true)
	const [showSettings, setShowSettings] = useState(false);
	const [adConsent, setAdConsent] = useState(true);
	const [analyticsConsent, setAnalyticsConsent] = useState(true);


	useEffect(() => {
		if(Cookies.get("CookieConsent") === "true") {
			setCookieConsent(true)
		}
		else {
			setCookieConsent(false)
		}
	}, [])

	const handleShow = () => {
		setShowSettings(!showSettings)
	}

	const handleAcceptAll = () => {
		Cookies.set("CookieConsent", "true", { expires: 365 });
		Cookies.set("CookieAdConsent", "granted", { expires: 365 });
		Cookies.set("CookieAnalyticsConsent", "granted", { expires: 365 });
		//Update consent for tracking settings
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({'event': 'updateConsent'});

		setCookieConsent(true)
	}
	const handleAcceptSelected = () => {
		const analytics = document.getElementById("AnalyticsConsent") as HTMLInputElement ;
		const ads = document.getElementById("AdConsent") as HTMLInputElement ;
		const adsCheck = ads.checked;
		const analyticsCheck = analytics.checked;
		Cookies.set("CookieConsent", "true", { expires: 365 });
		Cookies.set("CookieAdConsent", adsCheck ? 'granted' : 'denied', { expires: 365 });
		Cookies.set("CookieAnalyticsConsent", analyticsCheck ? 'granted' : 'denied', { expires: 365 });
		//Update consent for tracking settings
		window.dataLayer = window.dataLayer || [];  
		window.dataLayer.push({'event': 'updateConsent'});

		setCookieConsent(true)
	}

	return (
		<div>
		<StyledBackground className={showSettings ? "active" : ""}>
			<ClosingDiv onClick={handleShow} />
			<StyledModal className={showSettings ? "cookie-settings cookie-settings--show" : "cookie-settings"} >
				<CloseWrapper onClick={handleShow}>
				<StyledCloseSVG fill="#303030" width="max(24px, 1.6666vw)"  className="button-close" />
				</CloseWrapper>
				<ModalContentWrapper>
					<ModalContent>
						<h2>Stillingar fótspora</h2>
						<p>Hér getur þú stillt hvaða vefkökur þú vilt samþykkja á vefnum.</p>
						<ModalForm>
						<div className="cookie-form-group">
								
								<CheckboxLabel htmlFor="CoreConsent" className="disabled-check" title="Ekki er hægt að afvelja nauðsynlegar kökur.">
									<b>Nauðsynlegar vefkökur</b>(ómissandi til þess að vefsvæðin virki eins og ætlast er til)
									<input type="checkbox" name="CoreConsent" id="CoreConsent" checked readOnly />
									<CheckMarkConsent className="checkmark" height="max(24px, 1.6666vw)" width="max(24px, 1.6666vw)" />
								</CheckboxLabel>
								
							</div>
							<div className="cookie-form-group">
								<CheckboxLabel htmlFor="AnalyticsConsent">
									<b>Tölfræði- og aðlögunarkökur</b> (notaðar til að greina umferð um síðuna)
									<input type="checkbox" name="AnalyticsConsent" id="AnalyticsConsent" defaultChecked />
									<CheckMarkConsent className="checkmark" height="max(24px, 1.6666vw)" width="max(24px, 1.6666vw)" />
								</CheckboxLabel>
								
							</div>
							<div className="cookie-form-group">
								<CheckboxLabel htmlFor="AdConsent">
									<b>Þriðja aðila kökur</b> (notaðar til þess að birta auglýsingar hjá þriðja aðila)
									<input type="checkbox" name="AdConsent" id="AdConsent" defaultChecked/>
									<CheckMarkConsent className="checkmark" height="max(24px, 1.6666vw)" width="max(24px, 1.6666vw)" />
								</CheckboxLabel>
							</div>

							<button onClick={handleAcceptSelected} type="button" className="cookie-button">Samþykkja valdar kökur</button>
							</ModalForm>
						</ModalContent>
				</ModalContentWrapper>
			</StyledModal>
			</StyledBackground>
		<CookieConsent className={!cookieConsent && "show"}>
			<CookieConsentContent>
				<div className="cookie-text">
				We use cookies to improve your experience.<br/>
				By navigating the site, you agree to our Cookie Policy.
				</div>
			</CookieConsentContent>
			<CookieConsentButtonWrap>
			<button onClick={handleShow} type="button" className="cookie-button cookie-button--settings">Lesa nánar</button>
			<button onClick={handleAcceptAll} type="button" className="cookie-button">Samþykkja</button>
			</CookieConsentButtonWrap>
		</CookieConsent>
		</div>
	)
}

const CookieConsentButtonWrap = styled.div`
	display:flex;
	justify-content: space-between;
	align-items: center;
	@media ${mediaMax.mobileL} {
    flex-direction: column;
  }
`

const CloseWrapper = styled.div`
	cursor: pointer;
`
const CheckboxLabel = styled.label`
	padding-left: max(30px, 2.083vw);
	position: relative;
	cursor:pointer;
	&:before{
		content: '';
		position:absolute;
		top: 0;
		left: 0;
		height: max(24px, 1.6667vw);
		width: max(24px, 1.6667vw);
		background: white;
		border: 1px solid #e5e5e5;
		border-radius: 4px;
	}
	input[type=checkbox]{
		display:none;
	}
	.checkmark{
		display:none;
	}
	input[type=checkbox]:checked + .checkmark{
		display:block;
		position: absolute;
		left: 0;
		top: 0;
	}
	&.disabled-check{
		cursor:not-allowed;
		&:before{
			background: #e5e5e5;
	}
	@media ${mediaMax.mobileL} {
		label:before{
			top: 0.55vw;
		}
		input[type=checkbox]:checked + .checkmark{
			top: 0.55vw;
		}
  }

`

const ModalForm = styled.div`
	position: relative;
`
const ModalContent = styled.div`
	width: 100%;
	padding-top: max(30px, 4.8611vw);
	padding-left: max(15px, 6.9444vw);
	padding-bottom: max(70px, 4.8611vw);
	padding-right: max(15px,4.8611vw);
  h2 {
    color: #303030;
    font-family: ${theme.fonts.fontFamilyPrimary};
    font-size: max(20px, 2.0833vw);
    line-height: 126.667%;
    font-weight: bold;
    letter-spacing: 0;
		margin-bottom: 0.5em;
		width: 100%;
  }

  p {
		width: 100%;
    color: #606060;
    font-family: ${theme.fonts.fontFamilySecondary};
		font-style: normal;
		font-weight: normal;
		font-size: max(16px, 1.25vw);
		line-height: 155.55%;
    letter-spacing: 0;
    max-width: 90%;
    margin-bottom: 1.77em;
  }

  @media ${mediaMax.mobileL} {
		
    p {
      margin-bottom: 1.8em;
    }
  }
`
const ModalContentWrapper = styled.div`
	width: 100%;
	margin: 0 auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${mediaMax.tablet} {
    width: 90%;
  }

  @media ${mediaMax.mobileS} {
    margin-top: 0%;
  }
`
const ClosingDiv = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
`
const StyledCloseSVG = styled(CloseSVG)`
  position: absolute;
	top: max(13px, 0.902vw);
	right: max(13px, 0.902vw);
	border: 0;
	box-shadow: none;
`
const StyledModal = styled.div`
	width: auto;
	min-width: 53.8889vw;
  position: absolute;
  top: 15vh;
	height: max(400px, 30vw);
  bottom: 10vh;
  background-color: white;
  display: flex;
  z-index: 30;

  @media ${mediaMax.tablet} {
    width: 90vw;
    height: auto;
		top: 10vh;
		bottom: unset;
		max-height: 95%;
  }
`

const StyledBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  pointer-events: none;
  opacity: 0;
  z-index: 0;
  transition: z-index 0.5s step-end, opacity 0.5s cubic-bezier(0.8, 0, 0.07, 1);
  background: linear-gradient(
    135deg,
    rgba(42, 186, 217, 0.25) 0%,
    #8f20a1 100%
  );

  &.active {
    pointer-events: auto;
    transition: z-index 0.5s step-start,
      opacity 0.5s cubic-bezier(0.8, 0, 0.07, 1);
    z-index: 210;
    opacity: 1;
	}
	
	.cookie-settings{
		z-index: 2;
		display: none;
		font-family: ${theme.fonts.fontFamilySecondary};
		label{
			font-size: max(14px, 0.972vw);
			line-height: 2em;
		}
		input[readonly]{
			background-color: #e6e6e6;
		}
		.cookie-form-group{
			position: relative;
			margin-bottom: max(10px, 0.694vw);
			display:flex;
		}
		.cookie-settings__close{
			position:absolute;
			top: 0;
			right: 0;
		}
		.cookie-button{
			margin-top: 0.8888em;
			background: #98139C;
			border-radius: 25px;
			max-width: max(240px, 16.6666vw);
			font-family: ${theme.fonts.fontFamilySecondary};
			font-weight: bold;
			font-size: max(14px, 0.9722vw);
			line-height: 1.357em;
			text-transform: uppercase;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			padding-top: 3px;
			height: max(45px, 3.125vw);
			border: none;
			border-radius: 22.5px;
			width: 100%;
			color: #FFFFFF;
	
			&:focus {
				outline: none;
			}


		}
	}
	.cookie-settings--show{
		display:block;
		border-radius: 23px;
	}

`
const CookieConsentContent = styled.div`
	display:flex;
	flex-direction: column;
	max-width: 90%;
`

const CookieConsent = styled.div`
	position: fixed;
	right: 0;
	bottom: 0;
	width: 100%;
  border-radius: 3px;
  background-color: #121212;
	padding: max(30px, 2.08333vw);
	max-height: max(100px, 6.9444vw);
	display: none;
	z-index: 201;

	&.show {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	@media ${mediaMax.tablet} {
		right: 50%;
    transform: translateX(50%);
	}
	
	@media ${mediaMax.mobileL} {
    padding: max(10px, 2.08333vw);
  }

	.cookie-text {
		color: #FFF;
		font-family: "Open Sans";
		font-size: max(12px, 0.83333vw);
		letter-spacing: 0;
		line-height: 1.666em;
	}
	
	.cookie-button {
		cursor: pointer;
		margin: 0;
		border: none;
		border-radius: 30px;
		background: #FFF;
		width: max(142px, 9.8611vw);
		height: max(45px, 3.125vw);
		color: #000;
		font-family: ${theme.fonts.fontFamilySecondary};
		font-size: max(13px, 0.902777vw);
		font-weight: bold;
		letter-spacing: 0;
		line-height: 2.11em;
		transition: 0.3s ease;

		&:hover{
			background: #070707;
			color:#FFF;
			border: 1px solid rgba(255, 255, 255, 0.25);
		}
		&.cookie-button--settings{
			margin-right: max(10px, 0.69444vw);
			border: 1px solid rgba(255, 255, 255, 0.25);
			background: transparent;
			color: #FFF;
			&:hover{
				background: #070707;
				color:#FFF;
			}
		}
		&:focus {
			outline: none;
		}
	}
	@media ${mediaMax.mobileL} {
    .cookie-button.cookie-button--settings{
			margin-right: 0;
		}
  }
`