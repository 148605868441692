import React from 'react'
import { DisabledConditionOptions } from '../types'


const getOptions = (disabled: boolean):DisabledConditionOptions => {
	return {
		disabledHome: disabled,
		disabledBack: disabled,
		disabledGoLive: disabled,
		disabledClose: disabled,
		disabledSocial: disabled,
		disabledContact: disabled,
		disabledEye: disabled,
		disabledInfo: disabled,
	}
}

interface useDisableConditionProps {
	showContactForm?: boolean
}
export default ({
	showContactForm
}) => {

	if(showContactForm) {
		return {
			...getOptions(true),
			disabledContact: false,
			disabledClose: false
		}
	}

	return getOptions(false)
}