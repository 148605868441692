import styled from "styled-components"
import React from "react"

import Button, { IconContainer } from "./Button"
import MultimediaSVG from "../../Svg/Multimedia"
import FacebookSVG from "../../Svg/Facebook"
import TwitterSVG from "../../Svg/Twitter"

import { mediaMin, mediaMax } from "../../../utils/breakpoints"

interface ContactButtonProps {
  className?: string
  onClick?: () => void
  myDisabled?: boolean
}

export default ({ className, onClick, myDisabled }: ContactButtonProps) => {
  return (
    <SocialButton
      className={className}
      type="button"
      onClick={onClick}
      icon={<MultimediaSVG width="24px" className="icon" />}
      myDisabled={myDisabled}
    >
      <ButtonChild order={1} delay="0s" className="button-child">
        <TwitterSVG width="20px" />
      </ButtonChild>
      <ButtonChild order={2} className="button-child">
        <FacebookSVG width="18px" />
      </ButtonChild>
    </SocialButton>
  )
}

const getTranslate3d = (order: number) => {
  const percentage = 100 * order
  const paddingOffset = 10 * order
  return `translate3d(calc(-${percentage}% - ${paddingOffset}px),0,0)`
}

interface ButtonChildProps {
  order: number
  delay?: string
}
const ButtonChild = styled.div<ButtonChildProps>`
  width: 45px;
  height: 45px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-left: 10px;

  transform: ${({ order }) => getTranslate3d(order)};
  transition: transform 0.45s cubic-bezier(0.65, 0, 0.076, 1)
    ${({ delay }) => (delay ? delay : ``)};
`

const SocialButton = styled(Button)`
  .button-label {
    transition: none;
  }

  .button-label-content {
    background: transparent;
    transition: transform 0.45s step-end;
  }

  &:hover,
  &.active {
    .button-label {
      pointer-events: auto;
    }

    .button-label-content {
      transform: translate3d(0, 0, 0);
      transition: none;
    }

    ${ButtonChild} {
      transform: translate3d(0, 0, 0);
    }
  }

  @media ${mediaMax.tablet} {
    display: none;
  }
`
