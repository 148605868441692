import firebase from 'firebase/app';
import 'firebase/auth'; // importing the auth module as an example
import 'firebase/database'; // importing the auth module as an example

const config = {
  apiKey: "AIzaSyBkV6u6_v9kNlVWGUcv1-fOPVQWoKYZ3Ks",
  authDomain: "conceptevent-4e62f.firebaseapp.com",
  databaseURL: "https://conceptevent-4e62f.firebaseio.com",
  projectId: "conceptevent-4e62f",
  storageBucket: "conceptevent-4e62f.appspot.com",
  messagingSenderId: "71082236295",
  appId: "1:71082236295:web:2c2023e7ec56cf92015b22"
}

let instance = null;

export default function getFirebase() {
  if (typeof window !== 'undefined') {
    if (instance) return instance;
    instance = firebase.initializeApp(config);
    return instance;
  }

  return null;
}